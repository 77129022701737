import { gql, useMutation, useQuery } from "@apollo/client";
import { faBomb, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { head, isEmpty } from "lodash";
import * as React from "react";

import Following from "../../components/following";
import Heading from "../../components/heading";
import LatestMembers from "../../components/latest-members";
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import { getProfile } from "../../services/auth";

const GET_MEMBERS = gql`
  query GetMembers($id: Int!) {
    other_users: users(limit: 20, where: { id: { _neq: $id } }) {
      id
      name
      avatar
      username
      subscriptions {
        id
      }
    }
    current_user: users(limit: 20, where: { id: { _eq: $id } }) {
      following {
        followed_user_id
        following {
          avatar
          id
          name
          username
          subscriptions {
            id
          }
        }
      }
    }
  }
`;

const ADD_FOLLOWER = gql`
  mutation MyMutation($user_id: Int!, $followed_user_id: Int!) {
    insert_user_followers_one(
      object: { user_id: $user_id, followed_user_id: $followed_user_id }
    ) {
      id
    }
  }
`;

const FollowingPage = () => {
  const authenticatedUser = getProfile();

  const { data, loading, error } = useQuery(GET_MEMBERS, {
    variables: {
      id: authenticatedUser.id,
    },
  });

  const [follow] = useMutation(ADD_FOLLOWER, {
    refetchQueries: [
      {
        query: GET_MEMBERS,
        variables: {
          id: authenticatedUser.id,
        },
      },
    ],
  });

  const usersToFollow = isEmpty(data)
    ? []
    : data.other_users.filter(
        (otherUser) =>
          !head(data.current_user).following.some(
            ({ followed_user_id }) => followed_user_id === otherUser.id
          )
      );

  return (
    <Layout title="Following">
      <>
        <div className="flex flex-col w-full md:w-1/2 md:pr-8 transition-all duration-75 delay-75 ease-out">
          <Heading>Suggestions</Heading>
          {loading && <Loader />}
          {!loading && isEmpty(usersToFollow) && (
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faUmbrellaBeach} />
              Wow! You're following everyone. Time to relax.
            </div>
          )}
          {error && !isEmpty(data) && (
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faBomb} />
              <div>Something is blowing up. Please try again later.</div>
            </div>
          )}
          {data && !isEmpty(data) && (
            <LatestMembers
              members={usersToFollow}
              onFollow={(userToFollowId) => {
                follow({
                  variables: {
                    followed_user_id: userToFollowId,
                    user_id: authenticatedUser.id,
                  },
                });
              }}
            />
          )}
        </div>
        <div className="flex flex-col w-full mt-8 md:mt-0 md:w-1/2 md:pl-8 transition-all duration-75 delay-75 ease-out">
          <Heading>Following</Heading>
          {loading && <Loader />}
          {data && !isEmpty(data.current_user) && (
            <Following
              following={head(data.current_user).following.map(
                ({ following }) => ({ ...following })
              )}
            />
          )}
        </div>
      </>
    </Layout>
  );
};

export default FollowingPage;
