import { Link } from "gatsby";
import React, { useState } from "react";
import getDefaultImage from "../functions/getDefaultImage";
import Badge from "./badge";

import Button from "./button";
import Loader from "./loader";

export default function LatestMembers({ members, onFollow }) {
  const [isLoading, setIsLoading] = useState({});
  const filteredMembers = members.filter(({ username }) => !!username);
  return (
    <div className="h-max w-full">
      {filteredMembers.map((member, index) => (
        <div
          key={member.id}
          className={`flex flex-row justify-between items-center p-3 rounded-sm border-b-2 ${
            index === 0 ? "border-t-2" : ""
          } border-l-2 border-r-2 border-solid border-gray-100 w-full`}
        >
          <div className="flex flex-col w-1/4 pr-4">
            <img
              alt="avatar"
              className="rounded-lg h-16 w-16 rounded-md object-cover object-center shadow-sm"
              src={member.avatar || getDefaultImage(member)}
            />
          </div>
          <div className="flex flex-col w-1/2 pr-1">
            <div className="flex items-center">{member.name}<Badge className="ml-2" size="sm" subscriptions={member.subscriptions} /></div>
            <Link
              className="text-sm text-gray-500 hover:underline"
              to={`/app/library?id=${member.id}`}
            >
              @{member.username}
            </Link>
          </div>
          <div className="flex flex-col w-1/4">
            <Button
              onClick={() => {
                setIsLoading({
                  ...isLoading,
                  [member.id]: true,
                });
                onFollow(member.id);
              }}
            >
              {isLoading[member.id] ? <Loader /> : "Follow"}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
