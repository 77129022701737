import { Link as ClientLink } from "@reach/router";
import { isEmpty } from "lodash";
import moment from "moment";
import React from 'react';

import ImageEnlarger from "../../components/image-enlarger";
import Badge from "../../components/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading from "../../components/heading";
import SEO from '../../components/seo';
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { getProfile } from "../../services/auth";
import image from "../../images/roadmap.jpeg";

function Logo() {
  return (
    <ClientLink className="text-base font-heading tracking-widest" to="/app">
      periodical
    </ClientLink>
  );
}

export default function RoadmapPage() {
  const mainStyle = { minHeight: "calc(100vh - 133px)" };

  const { subscriptions } = getProfile();

  const hasClassy = !isEmpty(subscriptions);

  const Link = hasClassy ? 'a' : 'div';

  return (
    <>
      <SEO title="Roadmap" />
      <div className="p-6 md:p-0 flex flex-col items-center min-h-screen w-full overflow-x-hidden font-body">
        <div className="flex flex-col items-center max-w-screen-md w-full">
          <ClientLink to="/app" className="flex items-center pt-12 pb-4 text-5xl font-heading">periodical</ClientLink>
          <div className="text-center flex flex-row justify-center pb-3 text-lg font-heading border-double border-b-2 border-black w-full">
            “Plans are of little importance, but planning is essential.” ― Winston Churchill
          </div>
          <div className="flex flex-row justify-center items-center py-3 text-base font-heading border-double border-b-2 border-black w-full">
            {moment().format("LL")}
          </div>
        </div>
        <main
          className="flex flex-col mt-8 items-center max-w-screen-md w-full px-2 overflow-x-hidden border-solid border-2 border-black"
          style={mainStyle}
        >
          <div className="py-8 flex flex-col items-center w-full">
            <div className="flex flex-row justify-center pb-8">
              <h1 className="text-5xl md:text-7xl text-center font-bold font-heading">
                Planning New Features For Periodical
              </h1>
            </div>
            <div className="flex flex-row justify-center">
              <ImageEnlarger
                className="shadow-sm object-cover object-center h-auto w-full filter grayscale"
                alt="periodical"
                src={image}
              />
            </div>
          </div>
          <Heading className="text-center">Here's what we'd like to release at Periodical</Heading>
          <div className="flex flex-row flex-wrap items-center justify-center w-1-2 mx-2 pb-4 text-center">
            <p className="h-40 w-full md:w-1/2 mx-1 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
              <span className="flex items-center font-bold mb-1">Support RSS Feeds <Badge persist className="ml-2" /></span>
              Channel articles from an RSS feed into your library
            </p>
            <p className="my-8 mx-1 h-40 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
              <span className="flex items-center font-bold mb-1">Better Shelf Management <Badge persist className="ml-2" /></span>
              Expose a "merge tool" when cloning a user's shelf
            </p>
            <p className="h-40 mx-1 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
              <span className="font-bold mb-1">Improve the Look and Feel</span>
              Pour more effort into animations, performance optimizations, and a creative, newspaper-like look and feel.
            </p>
            <p className="my-8 mx-1 h-40 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
              <span className="font-bold mb-1">Add Article Reviews</span>
              Allow users to leave ratings and reviews on completed articles
            </p>
            <p className="h-40 mx-1 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
              <span className="font-bold mb-1">More Extensive Feed</span>
              Improve the feed by tracking more "events" from friends, exposing liking and commenting, etc.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center my-12">
            <Heading className="text-center">Want to go behind-the-scenes and help drive the direction of Periodical?</Heading>
            <Link className={`flex items-center text-xl ${hasClassy ? 'hover:underline' : ''}`} href="https://discord.gg/cHTu5ubW" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className="mr-2" icon={faDiscord} />
              <span className={`flex items-center ${!hasClassy ? 'line-through' : ''} text-xl`}>
                Join the Exclusive Discord Server
              </span>
            </Link>
            {!hasClassy && (
              <>
                <div className="text-center mt-4 flex items-center">Looks like your don't have a <Badge className="mx-1" persist /> account.</div>
                <div className="text-center mt-4">Click the "classy" badge to subscribe and get access to the Discord server.</div>
              </>
            )}
          </div>
        </main>
        <footer className="p-8 w-full flex items-end justify-end justify-self-end max-w-screen-lg">
          <Logo />
        </footer>
      </div>
    </>
  );
}