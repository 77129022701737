import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { faBomb, faScroll, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Router } from "@reach/router";
import { head, isEmpty, omit } from "lodash";
import React, { useEffect } from "react";

import LibraryPage from "./app/library";
import FollowingPage from "./app/following";
import EditProfilePage from "./app/edit-profile";
import ClassyPage from "./app/classy";
import SetupProfilePage from "./app/setup-profile";
import Feed from "../components/feed";
import Heading from "../components/heading";
import LatestMembers from "../components/latest-members";
import Layout from "../components/layout";
import Loader from "../components/loader";
import PrivateRoute from "../components/private-route";
import { getProfile, setProfile } from "../services/auth";
import SubscribedPage from "./app/subscribed";
import RoadmapPage from "./app/roadmap";
import ClientOnly from "../components/client-only";

const GET_USER = gql`
  query GetUser($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

const GET_MEMBERS = gql`
  query GetMembers($email: String!) {
    other_users: users(limit: 20, where: { email: { _neq: $email } }) {
      id
      name
      avatar
      username
      subscriptions {
        id
      }
    }
    current_user: users(where: { email: { _eq: $email } }) {
      id
      name
      avatar
      username
      following {
        followed_user_id
      }
      subscriptions {
        id
      }
    }
  }
`;

const GET_FEED = gql`
  query GetFeed($id: Int!, $following_ids: [Int!]!) {
    feed_events(
      where: {
        user_article: {
          user_id: { _neq: $id }
          _and: { user_id: { _in: $following_ids } }
        }
      }
      order_by: { created_at: desc }
      limit: 20
    ) {
      id
      created_at
      status {
        id
        name
      }
      user_article {
        article {
          cover
          author
          title
          url
          id
        }
        user {
          id
          name
          username
          subscriptions {
            id
          }
        }
      }
    }
  }
`;

const ADD_FOLLOWER = gql`
  mutation MyMutation($user_id: Int!, $followed_user_id: Int!) {
    insert_user_followers_one(
      object: { user_id: $user_id, followed_user_id: $followed_user_id }
    ) {
      id
    }
  }
`;

export default function App() {
  return (
    <ClientOnly>
      <Router basepath="/app">
        <PrivateRoute path="/" component={HomePage} />
        <PrivateRoute path="/library" component={LibraryPage} />
        <PrivateRoute path="/following" component={FollowingPage} />
        <PrivateRoute path="/setup-profile" component={SetupProfilePage} />
        <PrivateRoute path="/edit-profile" component={EditProfilePage} />
        <PrivateRoute path="/classy" component={ClassyPage} />
        <PrivateRoute path="/subscribed" component={SubscribedPage} />
        <PrivateRoute path="/roadmap" component={RoadmapPage} />
      </Router>
    </ClientOnly>
  );
}

const HomePage = () => {
  const authenticatedUser = getProfile();

  const [getFeed, { data: feedData, loading: feedLoading }] =
    useLazyQuery(GET_FEED);
  const [getMembers, { data, loading, error }] = useLazyQuery(GET_MEMBERS);
  const { loading: userLoading } = useQuery(GET_USER, {
    variables: {
      email: authenticatedUser.email,
    },
    onCompleted: () => {
      getMembers({
        variables: {
          email: authenticatedUser.email,
        },
      });
    },
  });

  const [follow] = useMutation(ADD_FOLLOWER, {
    refetchQueries: [
      {
        query: GET_MEMBERS,
        variables: {
          email: authenticatedUser.email,
        },
      },
    ],
  });

  const usersToFollow = isEmpty(data)
    ? []
    : data.other_users.filter(
        (otherUser) =>
          !head(data.current_user).following.some(
            ({ followed_user_id }) => followed_user_id === otherUser.id
          )
      );

  useEffect(() => {
    if (!isEmpty(data)) {
      const currentUser = head(data.current_user);
      setProfile(omit(currentUser, ["following"]));
      getFeed({
        variables: {
          id: currentUser.id,
          following_ids: currentUser.following.map(
            ({ followed_user_id }) => followed_user_id
          ),
        },
      });
    }
  }, [data]);

  const isLoading = userLoading || loading;

  return (
    <Layout title="Feed">
      <>
        <div className="flex flex-col w-full md:w-1/2 md:pr-8 transition-all duration-75 delay-75 ease-out">
          <Heading>Get inspired</Heading>
          {isLoading && <Loader />}
          {!isLoading && isEmpty(usersToFollow) && (
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faUmbrellaBeach} />
              Wow! You're following everyone. Time to relax.
            </div>
          )}
          {error && !isEmpty(data) && (
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faBomb} />
              <div>Something is blowing up. Please try again later.</div>
            </div>
          )}
          {data && !isEmpty(data) && (
            <LatestMembers
              members={usersToFollow}
              onFollow={(userToFollowId) => {
                follow({
                  variables: {
                    followed_user_id: userToFollowId,
                    user_id: authenticatedUser.id,
                  },
                });
              }}
            />
          )}
        </div>
        <div className="flex flex-col mt-8 md:mt-0 w-full md:w-1/2 md:pl-8 transition-all duration-75 delay-75 ease-out">
          <Heading>The Latest</Heading>
          {(isLoading || feedLoading) && <Loader />}
          {feedData && isEmpty(feedData) && (
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faScroll} />
              <div>Start following others to see their updates here.</div>
            </div>
          )}
          {feedData && !isEmpty(feedData) && (
            <Feed events={feedData.feed_events} />
          )}
        </div>
      </>
    </Layout>
  );
};
