import { gql, useMutation } from "@apollo/client";
import { navigate } from "@reach/router";
import { capitalize, head, partial, pick } from "lodash";
import React, { useState } from "react";

import Button from "../../components/button";
import ClientOnly from "../../components/client-only";
import Heading from "../../components/heading";
import ImageEnlarger from "../../components/image-enlarger";
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import TextInput from "../../components/text-input";
import { getProfile, setProfile } from "../../services/auth";

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $email: String!
    $avatar: String!
    $name: String!
  ) {
    update_users(
      where: { email: { _eq: $email } }
      _set: { avatar: $avatar, name: $name }
    ) {
      returning {
        id
        name
        avatar
      }
    }
  }
`;

function getSavedState(user) {
  return pick(user, ["avatar", "name"]);
}

export default function EditProfilePage() {
  const [formData, setFormData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: (data) => {
      const { avatar, name } = head(data.update_users.returning);
      setProfile({ name, avatar });
      setIsUploading(false);
      navigate('/app/library');
    },
  });

  function handleFile(name, event) {
    setFormData({
      ...formData,
      [name]: event.target.files[0],
    });
  }

  function handleText(name, event) {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
  }

  const user = getProfile();
  const savedState = getSavedState(user);

  async function handleSubmit(email, event) {
    event.preventDefault();

    setIsUploading(true);

    let body;

    if (formData.avatar) {
      const form = new FormData();
      form.append('avatar', formData.avatar);
      try {
        const response = await fetch('https://cloudinary-upload.glitch.me/upload', {
          method: "POST",
          body: form,
        });
        body = await response.json();
      } catch(error) {
        console.error(error);
      }
    }

    updateUserProfile({
      variables: {
        email,
        name: formData.name || savedState.name,
        avatar: body?.url || savedState.avatar || '',
      },
    });
  }

  return (
    <Layout>
      <ClientOnly>
        <div className="flex flex-col w-full">
          <Heading>Edit Profile</Heading>
          <ImageEnlarger
            className="object-cover object-center w-full md:w-1/3 rounded-sm shadow-sm mb-4 h-auto"
            src="https://images.unsplash.com/photo-1578402027442-b0d03cd2c0a6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1249&q=80"
          />
          <form
            className="flex flex-col w-3/4 md:w-1/4"
            onSubmit={partial(handleSubmit, user.email)}
          >
            <TextInput
              name="name"
              onChange={partial(handleText, "name")}
              placeholder="John Smith"
              value={formData.name || savedState.name}
            />
            <label className="font-heading">
              {capitalize('avatar')}
            </label>
            <input
              name="avatar"
              onChange={partial(handleFile, "avatar")}
              type="file"
            />
            <Button
              disabled={isUploading || loading}
              className={`${
                isUploading || loading
                  ? "cursor-not-allowed bg-gray-500 hover:bg-gray-500 hover:text-white"
                  : ""
              } mt-4`}
              type="submit"
            >
              {isUploading || loading ? 'Hang tight, updating profile...' : 'Edit profile'}
              {(isUploading || loading) && <Loader className="ml-2" />}
            </Button>
          </form>
        </div>
      </ClientOnly>
    </Layout>
  );
}
