import { Link } from "gatsby";
import { lowerCase, words, map, camelCase, join } from "lodash";
import moment from "moment";
import React from "react";

import { defaultStatuses } from "./statuses";
import Badge from './badge';
import getDefaultImage from "../functions/getDefaultImage";

function enumFromProperty(collection, property) {
  const entries = map(collection, (x) => [camelCase(x[property]), x[property]]);
  return Object.freeze(Object.fromEntries(entries));
}

const statuses = enumFromProperty(defaultStatuses, "name");

function isEqual(left, right) {
  return lowerCase(left) === lowerCase(right);
}

function pluralizeFirstWord(string) {
  return join(
    words(lowerCase(string)).map((word, index) =>
      index === 0 ? `${word}s` : word
    ),
    " "
  );
}

function active(eventName) {
  return `is ${lowerCase(eventName)}`;
}

function past(eventName) {
  return `has ${lowerCase(eventName)}`;
}

function plural(eventName) {
  return pluralizeFirstWord(eventName);
}

function getEventVerbiage(eventName) {
  if (isEqual(eventName, statuses.wantToRead)) {
    return plural(eventName);
  }

  if (isEqual(eventName, statuses.currentlyReading)) {
    return active(eventName);
  }

  if (isEqual(eventName, statuses.dropped)) {
    return past(eventName);
  }

  if (isEqual(eventName, statuses.read)) {
    return past(eventName);
  }
}

export default function Feed({ events }) {
  return (
    <div className="h-max w-full">
      {events.map(({ id, created_at, status, subscriptions, user_article: event }) => (
        <Link
          key={id}
          className="flex flex-col"
          to={`/app/library?id=${event.user.id}`}
        >
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex flex-row w-3/4 items-center pr-4">
              <img
                alt="avatar"
                className="h-8 w-8 mr-4 object-cover object-center rounded-full shadow-sm"
                src={event.user.avatar || getDefaultImage(event.user)}
              />
              <div className="flex items-center justify-start text-gray-500">{event.user.name}<Badge className="ml-1" size="sm" subscriptions={event.user.subscriptions} /></div>
              <div className="ml-2">{getEventVerbiage(status.name)}</div>
            </div>
            <div className="flex flex-row w-1/4 items-center">
              <div className="text-gray-500">
                {moment(created_at).fromNow()}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center mb-4 mx-12 p-4 rounded-sm border-solid border-2 border-gray-100">
            <div className="flex flex-col w-1/4 pr-4">
              <img
                alt="cover"
                className="h-16 w-16 filter grayscale object-cover object-center rounded-md shadow-sm"
                src={event.article.cover || getDefaultImage(event.user)}
              />
            </div>
            <div className="flex flex-col w-3/4">
              <div className="text-lg mb-1">{event.article.title}</div>
              <div className="text-sm text-gray-500">
                {event.article.author}
              </div>
            </div>
          </div>
          <hr className="my-4" />
        </Link>
      ))}
    </div>
  );
}
