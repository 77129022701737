import { Link } from "@reach/router";
import React from "react";
import getDefaultImage from "../functions/getDefaultImage";
import Badge from "./badge";

export default function Following({ following }) {
  return (
    <div className="h-max w-96">
      {following.map((user, index) => (
        <Link
          key={user.id}
          className={`flex flex-row items-center p-3 rounded-sm border-b-2 ${
            index === 0 ? "border-t-2" : ""
          } border-l-2 border-r-2 border-solid border-gray-100 w-full`}
          to={`/app/library?id=${user.id}`}
        >
          <div className="flex flex-col w-1/4 pr-4">
            <img
              alt="avatar"
              className="rounded-lg h-16 w-16 rounded-md object-cover object-center shadow-sm"
              src={user.avatar || getDefaultImage(user)}
            />
          </div>
          <div className="flex flex-col w-3/4 pr-1">
            <div className="flex items-center">{user.name}<Badge className="ml-2" size="sm" subscriptions={user.subscriptions} /></div>
            <div className="text-sm text-gray-500 hover:underline">
              @{user.username}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
