import { Link } from '@reach/router';
import moment from 'moment';
import React from 'react';

import Badge from '../../components/badge';
import Button from '../../components/button';
import ImageEnlarger from "../../components/image-enlarger";
import image from "../../images/subscribed.jpeg";

function Logo() {
  return (
    <Link className="text-base font-heading tracking-widest" to="/app">
      periodical
    </Link>
  );
}

export default function SubscribedPage() {
  const mainStyle = { minHeight: "calc(100vh - 133px)" };

  return (
    <div className="flex flex-col items-center min-h-screen w-full overflow-x-hidden font-body">
      <div className="flex flex-col items-center max-w-screen-md w-full">
        <Link to="/app" className="flex items-center pt-12 pb-4 text-5xl font-heading">periodical <Badge persist className="ml-4" /> </Link>
        <div className="flex flex-row justify-center pb-3 text-lg font-heading border-double border-b-2 border-black w-full">
          Congratulations, you are now a Classy user!
        </div>
        <div className="flex flex-row justify-center items-center py-3 text-base font-heading border-double border-b-2 border-black w-full">
          $2.99/month
          <span className="mx-4">|</span>
          {moment().format("LL")}
        </div>
      </div>
      <main
        className="flex flex-col mt-8 items-center max-w-screen-md w-full px-2 overflow-x-hidden border-solid border-2 border-black"
        style={mainStyle}
      >
        <div className="py-8 flex flex-col items-center w-full">
          <div className="flex flex-row justify-center pb-8">
            <h1 className="text-7xl text-center font-bold font-heading">
              New Subscriber to <span className="text-yellow-400 text-7xl">Classy</span>
            </h1>
          </div>
          <div className="flex flex-row justify-center">
            <ImageEnlarger
              className="shadow-sm object-cover object-center h-auto w-full filter grayscale"
              alt="periodical"
              src={image}
            />
          </div>
        </div>
        <Link className="pb-8" to="/app">
          <Button>
            Continue Using Periodical With Class
          </Button>
        </Link>
      </main>
      <footer className="p-8 w-full flex items-end justify-end justify-self-end max-w-screen-lg">
        <Logo />
      </footer>
    </div>
  );
}
