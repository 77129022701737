import { gql, useMutation } from '@apollo/client'
import { Link as ClientLink } from '@reach/router';
import { Link } from "gatsby";
import moment from "moment";
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { isNil, pick, noop } from 'lodash';
import React, { useEffect, useState } from 'react';

import Button from "../../components/button";
import ImageEnlarger from "../../components/image-enlarger";
import Badge from "../../components/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faTrophy } from "@fortawesome/free-solid-svg-icons";
import Heading from "../../components/heading";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { getProfile } from "../../services/auth";
import { state } from "../../components/provider";
import Loader from "../../components/loader";
import { navigate } from '@reach/router';
import SEO from '../../components/seo';
import ClientOnly from '../../components/client-only';
import image from "../../images/classy.jpeg";

const isBrowser = typeof window !== "undefined";

const INSERT_SUBSCRIPTION = gql`
  mutation InsertSubscription($customer_id: String!, $payment_method_id: String!, $subscription_id: String!, $user_id: Int!) {
    insert_subscriptions_one(object: {payment_method_id: $payment_method_id, subscription_id: $subscription_id, user_id: $user_id, customer_id: $customer_id}) {
      id
    }
  }
`;

function Logo() {
  return (
    <Link className="text-base font-heading tracking-widest" to="/app">
      periodical
    </Link>
  );
}

function createPaymentMethod(cardElement, stripe) {
  // Use your card Element with other Stripe.js APIs
  return stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: {
      ...pick(getProfile(), ['email']),
    },
  });
}

const CheckoutForm = ({ stripe: stripeContext }) => {
  const elements = useElements();
  const [stripe, setStripe] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [insertSubscription] = useMutation(INSERT_SUBSCRIPTION);

  useEffect(() => {
    stripeContext(instance => setStripe(instance));
  }, [stripeContext, setStripe])

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setError("Whoops! We're having issues connecting to Stripe");
      setLoading(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const paymentMethod = await createPaymentMethod(cardElement, stripe);

    if (paymentMethod.error) {
      setError(paymentMethod.error);
      return null;
    }

    let resp;

    try {
      const response = await fetch('https://fish-stripes.glitch.me/subscription', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profile: {
            ...pick(getProfile(), ['email']),
          },
          paymentMethod: pick(paymentMethod.paymentMethod, ['id']),
        }),
      });
      resp = await response.json();
    } catch(error) {
      setError("Whoops! Something went wrong when confirming your payment method.");
      setLoading(false);
      return null;
    }

    const userId = getProfile().id;
    insertSubscription({
      variables: {
        subscription_id: resp.subscription.id,
        customer_id: resp.customer.id,
        payment_method_id: resp.paymentMethod.id,
        user_id: userId,
      },
    });
    navigate('/app/subscribed');
  };

  return (
    <>
      {!isNil(error) && (
        <div className="w-full md:w-1/2 mt-8 bg-red-600 text-white py-2 px-4 rounded-md">
          <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
          {error.message}
        </div>
      )}
      <form className="w-full md:w-1/2 mt-8 pb-2" onSubmit={loading ? noop : handleSubmit}>
        <Heading className="text-center">Get Classy for $2.99/month</Heading>
        <CardElement className="mt-8 text-white" />
        <div className="mt-2 flex items-center text-sm">
          Secured with <FontAwesomeIcon className="ml-1 text-4xl" icon={faStripe} />
        </div>
        <Button data-splitbee-event="Subscribed" disabled={loading} className={`${loading ? 'bg-gray-500 cursor-not-allowed hover:bg-gray-500 hover:text-white' : ''} mt-4 px-3 w-full py-2 mb-2`} type="submit">
          {loading ? 'Hang tight, processing' : 'Process'} payment
          {loading && <Loader className="ml-2" />}
        </Button>
      </form>
    </>
  );
};

export default function CheckoutFirstClassPage() {
  const mainStyle = { minHeight: "calc(100vh - 133px)" };

  return (
    <state.Consumer>
      {context => (
        <ClientOnly>
          <SEO title="Get Classy" />
          <div className="p-6 md:p-0 flex flex-col items-center min-h-screen w-full overflow-x-hidden font-body">
            <div className="flex flex-col items-center max-w-screen-md w-full">
              <ClientLink to="/app" className="flex items-center pt-12 pb-4 text-5xl font-heading">periodical <Badge persist className="ml-4" /> </ClientLink>
              <div className="flex flex-row justify-center pb-3 text-lg font-heading border-double border-b-2 border-black w-full">
                Get a classier experience for less than the cost of Starbucks, only charged once a month.
              </div>
              <div className="flex flex-row justify-center items-center py-3 text-base font-heading border-double border-b-2 border-black w-full">
                $2.99/month
                <span className="mx-4">|</span>
                {moment().format("LL")}
              </div>
            </div>
            <main
              className="flex flex-col mt-8 items-center max-w-screen-md w-full px-2 overflow-x-hidden border-solid border-2 border-black"
              style={mainStyle}
            >
              <div className="py-8 flex flex-col items-center w-full">
                <div className="flex flex-row justify-center pb-8">
                  <h1 className="text-5xl md:text-7xl text-center font-bold font-heading">
                    Announcing Classier Features With Periodical <span className="text-yellow-400 text-5xl md:text-7xl">Classy</span>
                  </h1>
                </div>
                <div className="flex flex-row justify-center">
                  <ImageEnlarger
                    className="shadow-sm object-cover object-center h-auto w-full filter grayscale"
                    alt="periodical"
                    src={image}
                  />
                </div>
              </div>
              <Heading className="text-center">What You'll Get With a Classy Subscription</Heading>
              <div className="flex flex-row flex-wrap items-center justify-center w-1-2 mx-2 pb-4 text-center">
                <p className="h-40 w-full md:w-1/2 mx-1 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
                  <FontAwesomeIcon className="text-yellow-400 justify-self-start text-3xl mb-2" icon={faTrophy} />
                  <span className="font-bold mb-1">Better Article Management</span>
                  Build custom "shelves" to categorize your articles or clone those your friends have built.
                </p>
                <p className="my-8 mx-1 h-40 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
                  <FontAwesomeIcon className="text-yellow-400 text-3xl mb-2" icon={faTrophy} />
                  <span className="font-bold mb-1">Get New Classy Features</span>
                  Get access to all future "Classy" features.
                </p>
                <p className="h-40 mx-1 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
                  <FontAwesomeIcon className="text-yellow-400 text-3xl mb-2" icon={faTrophy} />
                  <span className="font-bold mb-1">Access to Classy-only Chat</span>
                  Get exclusive access to a Discord server to help drive the direction of product.
                </p>
                <p className="my-8 mx-1 h-40 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
                  <FontAwesomeIcon className="text-yellow-400 text-3xl mb-2" icon={faTrophy} />
                  <span className="font-bold mb-1">Rep Your Classiness</span>
                  Show your classiness (and support) with a special "Classy" badge next to your username.
                </p>
                <p className="h-40 mx-1 w-full md:w-1/2 flex flex-col justify-center items-center bg-black text-white py-2 px-4 relative">
                  <FontAwesomeIcon className="text-yellow-400 text-3xl mb-2" icon={faTrophy} />
                  <span className="font-bold mb-1">First Class Priority</span>
                  Move to the front of the line for customer support.
                </p>
              </div>
              {isBrowser && <CheckoutForm stripe={context?.stripe} />}
              <ClientLink className="pb-8 text-center text-gray-700 hover:underline cursor-pointer" to="/app">
                I'll pass on a classier experience for now.
              </ClientLink>
            </main>
            <footer className="p-8 w-full flex items-end justify-end justify-self-end max-w-screen-lg">
              <Logo />
            </footer>
          </div>
        </ClientOnly>
      )}
    </state.Consumer>
  );
}