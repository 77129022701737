import { navigate } from "@reach/router";
import React from "react";
import { login, isAuthenticated, getProfile } from "../services/auth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isAuthenticated()) {
    login();
    return null;
  }

  const authenticatedUser = getProfile();

  return <Component {...rest} authenticatedUser={authenticatedUser} />;
};

export default PrivateRoute;
